<template>
  <div>
    <header id="header">
      <div class="container">
        <div class="header">
          <div class="logo">
            <img src="landing/assets/images/logo.svg" alt="wiplan" />
          </div>
          <div class="header-button">
            <router-link
              to="/free-registration"
              class="btn header-btn header-btn--demo router-link"
              data-route="registration"
              >Попробовать бесплатно</router-link
            >
            <router-link
              to="/login"
              class="btn header-btn header-btn--login router-link"
              data-route="login"
              >Войти</router-link
            >
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <h1>Политика конфиденциальности персональных данных</h1>
      <p>
        Настоящая Политика конфиденциальности персональных данных (далее –
        Политика конфиденциальности) действует в отношении всей информации,
        которую Сайт, расположенный на домене wiplan.ru, и мобильное приложение
        Wiplan, может получить о Пользователе во время использования Сайта и
        мобильного приложения.
      </p>
      <ol>
        <h3>ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h3>
        <li>
          1.1. В настоящей Политике конфиденциальности используются следующие
          термины:
        </li>
        <ul>
          <li>
            1.1.1. «Администрация сайта (далее – Администрация сайта)» –
            уполномоченные сотрудники на управления сайтом и мобильным
            приложением, которые организуют и (или) осуществляет обработку
            персональных данных, а также определяет цели обработки персональных
            данных, состав персональных данных, подлежащих обработке, действия
            (операции), совершаемые с персональными данными.
          </li>
          <li>
            1.1.2. «Персональные данные» - любая информация, относящаяся к прямо
            или косвенно определенному или определяемому физическому лицу
            (субъекту персональных данных).
          </li>
          <li>
            1.1.3. «Обработка персональных данных» - любое действие (операция)
            или совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </li>
          <li>
            1.1.4. «Конфиденциальность персональных данных» - обязательное для
            соблюдения Оператором или иным получившим доступ к персональным
            данным лицом требование не допускать их распространения без согласия
            субъекта персональных данных или наличия иного законного основания.
          </li>
          <li>
            1.1.5. «Пользователь сайта и мобильного приложения (далее
            Пользователь)» – лицо, имеющее доступ к Сайту или мобильному
            приложению, посредством сети Интернет.
          </li>
          <li>
            1.1.6. «Cookies» — небольшой фрагмент данных, отправленный
            веб-сервером и хранимый на компьютере пользователя, который
            веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
            HTTP-запросе при попытке открыть страницу соответствующего сайта.
          </li>
          <li>
            1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной
            сети, построенной по протоколу IP.
          </li>
          <li>
            1.1.8. «Мобильное приложение Wiplan» — программное обеспечение,
            которое устанавливается на мобильных устройствах, таких как
            смартфоны или планшеты и с помощью которого происходит
            взаимодействие пользователей с сайтом.
          </li>
        </ul>

        <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
        <li>
          2.1. Использование Пользователем сайта и мобильным приложением
          означает согласие с настоящей Политикой конфиденциальности и условиями
          обработки персональных данных Пользователя.
        </li>
        <li>
          2.2. В случае несогласия с условиями Политики конфиденциальности
          Пользователь должен прекратить использование сайта и мобильного
          приложения.
        </li>
        <li>
          2.3. Настоящая Политика конфиденциальности применяется только к сайту
          расположенному на на домене wiplan.ru, и мобильному приложению Wiplan.
          Сайт не контролирует и не несет ответственность за сайты третьих лиц,
          на которые Пользователь может перейти по ссылкам, доступным на сайте.
        </li>
        <li>
          2.4. Администрация сайта не проверяет достоверность персональных
          данных, предоставляемых Пользователем сайта.
        </li>

        <h3>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>
        <li>
          3.1. Настоящая Политика конфиденциальности устанавливает обязательства
          Администрации сайта по неразглашению и обеспечению режима защиты
          конфиденциальности персональных данных, которые Пользователь
          предоставляет по запросу Администрации сайта при регистрации на сайте
          или при оформлении заказа для приобретения Товара.
        </li>
        <li>
          3.2. Персональные данные, разрешённые к обработке в рамках настоящей
          Политики конфиденциальности, предоставляются Пользователем путём
          заполнения регистрационной формы на Сайте и включают в себя следующую
          информацию:
        </li>
        <ul>
          <li>3.2.1. фамилию, имя, отчество Пользователя;</li>
          <li>3.2.2. контактный телефон Пользователя;</li>
          <li>3.2.3. адрес электронной почты (e-mail);</li>
          <li>
            3.3.1. Отключение cookies может повлечь невозможность доступа к
            частям сайта, требующим авторизации.
          </li>
        </ul>
        <li>
          3.4. Иннформация о запущенных приложениях на мобильном устройстве,
          когда мобильное приложение Wiplan установлено и запущено. К такой
          информации относятся названия приложений и время работы приложений.
        </li>
        <li>
          3.5. Любая иная персональная информация неоговоренная выше подлежит
          надежному хранению и нераспространению, за исключением случаев,
          предусмотренных в п.п. 5.2. настоящей Политики конфиденциальности.
        </li>
        <h3>
          4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
        </h3>
        <li>
          4.1. Персональные данные Пользователя Администрация сайта может
          использовать в целях:
        </li>
        <ul>
          <li>
            4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для
            оформления заказа и (или) заключения Договора купли-продажи товара.
          </li>
          <li>
            4.1.2. Предоставления Пользователю доступа к персонализированным
            ресурсам сайта.
          </li>
          <li>
            4.1.3. Установления с Пользователем обратной связи, включая
            направление уведомлений, запросов, касающихся использования сайта,
            оказания услуг, обработка запросов и заявок от Пользователя.
          </li>
          <li>
            4.1.4. Определения места нахождения Пользователя для обеспечения
            безопасности, предотвращения мошенничества.
          </li>
          <li>
            4.1.5. Подтверждения достоверности и полноты персональных данных,
            предоставленных Пользователем.
          </li>
          <li>
            4.1.6. Предоставления Пользователю эффективной клиентской и
            технической поддержки.
          </li>
          <li>
            4.1.7. Подсчет эффективного времени работы пользователя мобильного
            приложения Wiplan.
          </li>
        </ul>
        <h3>
          5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
        </h3>
        <li>
          5.1. Обработка персональных данных Пользователя осуществляется без
          ограничения срока, любым законным способом, в том числе в
          информационных системах персональных данных с использованием средств
          автоматизации или без использования таких средств.
        </li>
        <li>
          5.2. Персональные данные Пользователя могут быть переданы
          уполномоченным органам государственной власти Российской Федерации
          только по основаниям и в порядке, установленным законодательством
          Российской Федерации.
        </li>
        <li>
          5.3. При утрате или разглашении персональных данных Администрация
          сайта информирует Пользователя об утрате или разглашении персональных
          данных.
        </li>
        <li>
          5.4. Администрация сайта принимает необходимые организационные и
          технические меры для защиты персональной информации Пользователя от
          неправомерного или случайного доступа, уничтожения, изменения,
          блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц.
        </li>
        <li>
          5.5. Администрация сайта совместно с Пользователем принимает все
          необходимые меры по предотвращению убытков или иных отрицательных
          последствий, вызванных утратой или разглашением персональных данных
          Пользователя.
        </li>

        <h3>6. ОБЯЗАТЕЛЬСТВА СТОРОН</h3>
        <li>6.1. Пользователь обязан:</li>
        <ul>
          <li>
            6.1.1. Предоставить информацию о персональных данных, необходимую
            для пользования Сайтом и мобильным приложением.
          </li>
          <li>
            6.1.2. Обновить, дополнить предоставленную информацию о персональных
            данных в случае изменения данной информации.
          </li>
        </ul>
        <li>6.2. Администрация сайта обязана:</li>
        <ul>
          <li>
            6.2.1. Использовать полученную информацию исключительно для целей,
            указанных в п. 4 настоящей Политики конфиденциальности.
          </li>
          <li>
            6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
            разглашать без предварительного письменного разрешения Пользователя,
            а также не осуществлять продажу, обмен, опубликование, либо
            разглашение иными возможными способами переданных персональных
            данных Пользователя, за исключением п.п. 5.2. настоящей Политики
            Конфиденциальности.
          </li>
          <li>
            6.2.3. Принимать меры предосторожности для защиты конфиденциальности
            персональных данных Пользователя согласно порядку, обычно
            используемого для защиты такого рода информации в существующем
            деловом обороте.
          </li>
          <li>
            6.2.4. Осуществить блокирование персональных данных, относящихся к
            соответствующему Пользователю, с момента обращения или запроса
            Пользователя или его законного представителя либо уполномоченного
            органа по защите прав субъектов персональных данных на период
            проверки, в случае выявления недостоверных персональных данных или
            неправомерных действий.
          </li>
        </ul>
        <h3>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
        <li>
          7.1. Администрация сайта, не исполнившая свои обязательства, несёт
          ответственность за убытки, понесённые Пользователем в связи с
          неправомерным использованием персональных данных, в соответствии с
          законодательством Российской Федерации, за исключением случаев,
          предусмотренных п.п. 5.2. и 7.2. настоящей Политики
          Конфиденциальности.
        </li>
        <li>
          7.2. В случае утраты или разглашения Конфиденциальной информации
          Администрация сайта не несёт ответственность, если данная
          конфиденциальная информация:
        </li>
        <ul>
          <li>
            7.2.1. Стала публичным достоянием до её утраты или разглашения.
          </li>
          <li>
            7.2.2. Была получена от третьей стороны до момента её получения
            Администрацией сайта.
          </li>
          <li>7.2.3. Была разглашена с согласия Пользователя.</li>
        </ul>
        <h3>8. РАЗРЕШЕНИЕ СПОРОВ</h3>
        <li>
          8.1. До обращения в суд с иском по спорам, возникающим из отношений
          между Пользователем сайта и Администрацией сайта, обязательным
          является предъявление претензии (письменного предложения о
          добровольном урегулировании спора).
        </li>
        <li>
          8.2 Получатель претензии в течение 30 календарных дней со дня
          получения претензии, письменно уведомляет заявителя претензии о
          результатах рассмотрения претензии.
        </li>
        <li>
          8.3. При не достижении соглашения спор будет передан на рассмотрение в
          судебный орган в соответствии с действующим законодательством
          Российской Федерации.
        </li>
        <li>
          8.4. К настоящей Политике конфиденциальности и отношениям между
          Пользователем и Администрацией сайта применяется действующее
          законодательство Российской Федерации.
        </li>
        <h3>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
        <li>
          9.1. Администрация сайта вправе вносить изменения в настоящую Политику
          конфиденциальности без согласия Пользователя.
        </li>
        <li>
          9.2. Новая Политика конфиденциальности вступает в силу с момента ее
          размещения на Сайте, если иное не предусмотрено новой редакцией
          Политики конфиденциальности.
        </li>
      </ol>
      <br />
      <p>
        Если у Вас возникли вопросы по работе приложения, обратитесь,
        пожалуйста, в
        <router-link to="/help?tab=ask">службу поддержки.</router-link>
      </p>
    </div>
    <footer id="footer">
      <div class="container">
        <h2 class="footer-title">
          Начни работать в Wiplan <br />
          прямо сейчас
        </h2>
        <div class="button-block">
          <div class="button-try">
            <a
              href="https://wiplan.ru/free-registration"
              class="btn button-block__btn router-link"
              data-route="registration"
            >
              Попробовать бесплатно
            </a>
          </div>
          <div class="button-block__app">
            <a href="landing/assets/Wiplan.ipa" download class="btn-app">
              <img src="landing/assets/images/apple-2.svg" alt="Wiplan" />
            </a>
            <a
              href="landing/assets/wiplan.19.04.24.apk"
              download
              class="btn-app"
            >
              <img src="landing/assets/images/android-2.svg" alt="Wiplan" />
            </a>
          </div>
        </div>
        <div class="footer">
          <div class="footer-item">
            © Wiplan 2024
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Agreement",
  data() {
    return {
      date: new Date().getFullYear()
    };
  }
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

ul > li {
  list-style: none;
}

ol > li {
  list-style: none;
}

.btn {
  border-radius: 8px;
  box-shadow: 0px 10px 40px rgba(98, 118, 169, 0.2);
}

.btn:hover {
  color: #fff;
  background: #198ad1;
  box-shadow: 0px 10px 40px rgba(98, 118, 169, 0.2);
}

a {
  text-decoration: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .header-btn {
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.03em;
    background: transparent;
    padding: 14px 16px;
    border: 1px solid #fff;
  }

  .header-btn--login {
    margin-left: 16px;
  }

  .header-btn:hover {
    background: #198ad1;
    border: 1px solid #198ad1;
  }
}

#footer {
  background: url(../assets/images/footer-bg.png) no-repeat center top/cover;
  padding-top: 100px;
  padding-bottom: 80px;

  .container {
    padding: 0 15%;
    margin: 0 auto;
  }

  @media (max-width: 1800px) {
    .container {
      padding: 0 10%;
      margin: 0 auto;
    }
  }

  @media (max-width: 1550px) {
    .container {
      padding: 0 7%;
      margin: 0 auto;
    }
  }

  @media (max-width: 1550px) {
    .container {
      padding: 0 5%;
      margin: 0 auto;
    }
  }

  @media (max-width: 1250px) {
    .container {
      padding: 0 3%;
      margin: 0 auto;
    }
  }

  .footer-title {
    font-weight: 400;
    font-size: 40px;
    line-height: 124%;
    letter-spacing: 0.03em;
    color: #fff;
    margin-bottom: 100px;
  }

  .button-block {
    width: 367px;

    .button-try {
      margin-top: 40px;

      .btn {
        font-weight: 700;
        font-size: 17px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #fff;
        padding: 20px;
        border: none;
        background: #1199f0;
        border-radius: 8px;
        box-shadow: 0px 10px 40px rgba(98, 118, 169, 0.2);
      }

      .btn:hover {
        background: #198ad1;
        box-shadow: 0px 10px 40px rgba(98, 118, 169, 0.2);
      }

      .button-block__btn {
        display: block;
        text-align: center;
      }
    }

    .button-block__app {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .btn-app {
        background: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 175px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 105px;

    .footer-item {
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: #fff;
    }
  }
}

@media (max-width: 810px) {
  #footer {
    background: url("../assets/images/footer-bg.png") no-repeat right 31%
      top/cover;
  }
}

@media (max-width: 576px) {
  #footer {
    padding-top: 40px;
    padding-bottom: 10px;

    .footer-title {
      font-size: 22px;
      line-height: 150%;
      margin-bottom: 30px;
    }
  }
}
</style>
